<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">


                <!-- <tchtree v-model="searchForm.class_ids" ref="classTree"></tchtree>
                <el-input placeholder="学生姓名" v-model="searchForm.keyword" size="small" class="input-with-select"
                    style="width:200px" clearable>
                </el-input> -->


            </div>
        </el-row>
        <el-row v-if="false" class="searchBar flexBetween" style="flex-wrap: wrap;width: 100%;">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">
                <el-select v-model="searchForm.anpai_type" clearable placeholder="安排方式" size="small"
                    style="width:130px;">
                    <el-option :label="item" v-for="(item, idx) in anpaiObj" :key="idx" :value="idx"></el-option>
                </el-select>

                <el-select v-model="searchForm.is_ok" clearable placeholder="专业是否对口" size="small"
                    style="width:130px;margin-left:10px;">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                </el-select>

                <el-select v-model="searchForm.insured" clearable placeholder="是否投保" size="small"
                    style="width:130px;margin-left:10px;">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                </el-select>

                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                    @click="() => { page.current_page = 1; getList() }">搜索</el-button>
            </div>
        </el-row>
        <div class="dividerBar"></div>
        <div class="cusTabTabContainer">
            <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
                style="width:fit-content;width:100%;">
                <el-table-column type="index" label="序号" width="55px" align="center" />
                <el-table-column label="学生姓名" prop="stu_name" width="100px" align="center" />
                <el-table-column label="所在班级" prop="class_name" show-overflow-tooltip width="120px" align="center" />
                <!-- <el-table-column label="系部" prop="yx_name" show-overflow-tooltip width="150px" align="center" /> -->

                <el-table-column label="实习企业" prop="firm_name" show-overflow-tooltip min-width="200px" align="center">
                    <template slot-scope="scope">
                        <span style="">
                            {{ scope.row.remark &&
                            scope.row.remark.stu_enter_info ? scope.row.remark.stu_enter_info.firm_name : '' }}
                        </span>

                    </template>
                </el-table-column>
                <el-table-column label="上岗日期" prop="start_time" show-overflow-tooltip width="140px" align="center">
                    <template slot-scope="scope">{{ scope.row.leave_start ? scope.row.leave_start.split(" ")[0] : ''
                        }}</template>
                </el-table-column>
                <el-table-column label="离岗日期" prop="end_time" show-overflow-tooltip width="120px" align="center">
                    <template slot-scope="scope">{{ scope.row.leave_end ? scope.row.leave_end.split(" ")[0] : ''
                        }}</template>
                </el-table-column>

                <el-table-column label="提交时间" prop="cdate" show-overflow-tooltip width="200px" align="center">
                    <template slot-scope="scope">{{ scope.row.cdate }}</template>
                </el-table-column>
                <el-table-column label="是否上传盖章" prop="sx_status" width="90px" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.leave_url" style="color: #409eff;flex-shrink: 0;">
                            已上传
                        </span>
                        <span v-if="!scope.row.leave_url" style="color: #EF342E;flex-shrink: 0;">
                            未上传
                        </span>
                    </template>
                </el-table-column>

                <el-table-column label="审批状态" prop="check_status" width="100px" align="center">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.check_status == 0" type="primary">待审核</el-tag>
                        <el-tag size="mini" v-if="scope.row.sp_status == 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.sp_status == 2" type="danger">审核拒绝</el-tag>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-tag class="pointer" @click="viewInfo(scope.row)" type="primary" size="mini">查看</el-tag>

                        <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info"
                            iconColor="red" @confirm="handleDelete(scope.row)" placement="left" title="是否确定删除？">
                            <el-tag class="pointer" slot="reference" type="danger" size="mini"
                                style="margin-left: 10px">删除</el-tag>
                        </el-popconfirm>
                        <div>
                            <el-tag class="pointer"  @click="exportPdf(scope.row, 'edit')" type="success"
                                size="mini">导出离岗申请表（未盖章）</el-tag>

                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
            :current-page="page.current_page" :page-size="page.per_page" :total="page.count"
            layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>
        <formdialog v-if="dialogshow" :EditItem1="EditItem" :id="EditItem.id"></formdialog>
    </div>
</template>

<script>
import formdialog from "./leaveIntership_formdialog.vue"

import tchtree from "../com/tchTree.vue";
import { mapGetters } from "vuex";

export default {
    components: { tchtree, formdialog },
    data() {
        return {
            dialogshow: false,

            activeName: "first",
            activeTable: "first",
            isRevoke: false,
            searchForm: {
                ktype: "stu_name",
                keyword: "",
                sxqx: "",
                check_status: "",
                class_ids: "",
                anpai_type: "",
                is_effect: "",
            },
            tableData: [],
            dialogForm: false,
            modalTitle: "添加",
            formData: {},
            sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
            qyxz: [], qyxzObj: {},
            salary: [], salaryObj: {},
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            sxqxArr: [], sxqxObj: {},
            anpaiObj: {
                0: '学校安排', 1: '自主选择', 2: '定向培养'
            },
            loading: false,
            // 选点
            center: [116.397477, 39.908692],
       
           
            texts: [
                {
                    position: [116.397477, 39.908692],
                    text: '天安门',
                    offset: [0, -70]
                }
            ],
            salaryOption: [],
            isSysAdmin: false,
            EditItem: {},
        };
    },
    mounted() {
        this.getComType().then(rr => {
            this.getList();
        });
       
        this.$http.post("/api/get_teacher_info").then(res => {
            if (res.data.sys_roles) {
                for (let item of res.data.sys_roles) {
                    if (item.role_name == '系统管理员' || item.role_code.indexOf("sysadmin") >= 0) {
                        this.isSysAdmin = true
                        break
                    }
                }
            }
        })
    },
    computed: {
        ...mapGetters(['isGradeLeader']),
    },
    methods: {
        viewInfo(row, type) {
            this.isView = type === 'show'
            this.EditItem = row
            this.dialogshow = true;
        },
        
        getComType() {
            return new Promise(resolve => {
                this.$http
                    .post("/api/sys_param_list", {
                        param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
                        used: 0
                    })
                    .then(res => {
                        let qyxz = [];
                        let salary = [];
                        let sxqxArr = [];
                        let qyxzObj = {};
                        let salaryObj = {};
                        let sxqxObj = {};
                        let salaryOption = [];
                        for (let item of res.data) {
                            if (item.param_type == "ENTER_INFO") {
                                qyxz.push(item);
                                qyxzObj[item.param_value] = item.param_desc
                            }
                            if (item.param_type == "SALARY_RANGE") {
                                salary.push(item);
                                salaryObj[item.param_value] = item.param_desc
                                let aa = JSON.parse(JSON.stringify(item.param_desc))
                                aa = item.param_desc.split('-')
                                if (aa && aa[0]) { aa[0] = parseInt(aa[0]); }
                                if (aa && aa[1]) { aa[1] = parseInt(aa[1]) }
                                salaryOption.push(aa)
                            }
                            if (item.param_type == "SHIXI_STATUS") {
                                sxqxArr.push(item);
                                sxqxObj[item.param_value] = item.param_desc
                            }
                        }
                        this.qyxz = qyxz;
                        this.salary = salary;
                        this.sxqxArr = sxqxArr;
                        this.qyxzObj = qyxzObj;
                        this.salaryObj = salaryObj;
                        this.salaryOption = salaryOption
                        this.sxqxObj = sxqxObj;
                        resolve();
                    });
            });
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },
        formAdd() {
            this.dialogForm = true;
            this.modalTitle = "添加";
            this.formData = {};
        },
        getList() {
            let data = {};
            data.page = this.page.current_page;
            data.pagesize = this.page.per_page;

            this.tableData = [];
            this.$http.post("/api/stu_intership_leave_list", data).then(res => {
                res.data.data.map(a => {
                    if (a.remark) {
                        try {
                            a.remark = JSON.parse(a.remark)
                        } catch (error) {
                            a.remark = { contact: "", contactTel: "" }
                        }
                    }
                })

                this.tableData = res.data.data;
                this.page = res.data.page;
            });
        },
        handleDelete(row) {
            this.$http
                .post("/api/stu_intership_leave_list_delete", {
                    id: row.id
                })
                .then(res => {
                    this.$message.success("删除成功");
                    this.getList();
                });
        },
        exportPdf(row) {
            console.log(357,row)
            let params = {
                "stuArr": [{
                    "id": row.stu_id,
                    "dataid": row.id,
                    "name": row.stu_name,
                }],
                "pmid": window.pmid,
                "latest": 1,
            }
            this.$http
                .post(this.$pdfUrl + "/exportTchPdf_intership_leave", params)
                .then(res => {
                    this.$message({
                        type: 'success',
                        message: '生成成功!'
                    });
                    window.open(res.data.url, "_blank")
                }); 
        },
    
    
    }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
    padding: 0px 21px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}

/deep/ .el-tabs__item {
    font-size: 18px;
}

.dialog_right {
    .lineRow {
        .cell:first-of-type {
            width: 120px;
            padding-left: 8px;
        }

    }

    .imgaddbtn {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px dotted #ccc;
        font-size: 30px;
        cursor: pointer;
    }

    .imgremove {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;

        cursor: pointer;
        position: absolute;
        top: -5px;
        right: -5px;
        color: #ccc;
    }

    .imgremove:hover {
        color: orange;
    }
}

.cusTabTabContainer {
    flex: 1;
    width: 100%;

    ::v-deep .el-tabs {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    ::v-deep .el-tabs__content {
        flex: 1;
    }

    ::v-deep .el-tab-pane {
        height: 100%;
    }
}
</style>