<template>
	<div>
		<el-dialog title="离岗申请" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="true"
			:destroy-on-close="true" @close="closeDialog" width="800px" custom-class="cus_dialog">
			<div class="cellGroupBarTitle flexStart" style="margin-top: 10px">
				<span></span>
				<span style="font-weight: 400">学生实习信息</span>
			</div>
			<table class="dttable" v-if="EditItem">
				<tr>
					<td class="tdheader">姓名</td>
					<td>{{ EditItem.stu_name }}</td>

					<td class="tdheader">所在班级</td>
					<td>{{ EditItem.class_name }}</td>
				</tr>
				<tr>
					<td class="tdheader">专业</td>
					<td>{{ EditItem.yuanxi_name }}</td>
					<td class="tdheader">实习单位</td>
					<td>{{ (EditItem.remark && EditItem.remark.stu_enter_info &&
						EditItem.remark.stu_enter_info.firm_name) ? EditItem.remark.stu_enter_info.firm_name :'-' }}</td>
				</tr>
				<tr>
					<td class="tdheader">实习岗位</td>
					<td colspan="3">{{ (EditItem.remark && EditItem.remark.stu_enter_info &&
						EditItem.remark.stu_enter_info.job) ? EditItem.remark.stu_enter_info.job :'-' }}</td>
				</tr>

			</table>
			<div class="cellGroupBarTitle flexStart" style="margin-top: 10px">
				<span></span>
				<span style="font-weight: 400">离岗申请</span>
			</div>
			<table class="dttable" v-if="EditItem">
				<tr>
					<td class="tdheader">单位负责人电话</td>
					<td>{{ EditItem.remark.mentor_tel }}</td>
					<td class="tdheader">家长电话</td>
					<td>{{ EditItem.remark.contactTel }}</td>

				</tr>
				<tr>
					<td class="tdheader">上岗日期</td>
					<td>{{ EditItem.leave_start }}</td>
					<td class="tdheader">离岗日期</td>
					<td>{{ EditItem.remark.mentor_tel }}</td>
				</tr>
				<tr>
					<td class="tdheader">离岗原因</td>
					<td colspan="3">{{ EditItem.leave_content }}</td>
				</tr>
				<tr>
					<td class="tdheader">
						离岗申请表拍照</td>
					<td colspan="3">
						<div v-if="!EditItem.fieldname">-</div>
						<div style="display: inline-block; margin-right: 10px; vertical-align: top; position: relative"
							v-for="(img, idx) in EditItem.fieldname" :key="idx">
							<el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
								:preview-src-list="[img.fileUrl || img.src]" fit="cover" :z-index="3000"></el-image>
							<!-- 
                <div class="imgremove" v-if="EditItem.is_draft == 1" @click="removeImg(idx)"><i class="el-icon-remove"></i></div> -->
						</div>

						<!-- <div style="display: inline-block; vertical-align: top" v-if="EditItem.is_draft == 1">
                <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false" name="image">
                  <div class="imgaddbtn">＋</div>
                </el-upload>
              </div> -->
					</td>
				</tr>
			</table>
			<div style="height:50px;"></div>
		</el-dialog>
	</div>
</template>

<script>

export default {
	components: {},
	props: ["id", "isView"],
	data() {
		return {
			dialogshow: true,
			userinfo: null,
			EditItem: {
				id: 0,
				leave_type: "",
				title: "",
				leave_start: "",
				leave_start_time: "",
				leave_end: "",
				leave_end_time: "",
				leave_num: "",
				content: "",
				files: [],
				process_id: 0,
				remark: "",
				status: 1

			},
			process_code: "leave_1",
			needChoose: false,//是否需要选择下级
			choosedUser: [],//已选择的用户.
			spword: ""

		}

	},
	mounted() {
		this.getTeacheInfo()
		this.getData()
	},
	methods: {
		getTeacheInfo() {
			this.$http.post("/api/get_teacher_info").then(res => {
				this.userinfo = res.data
				this.$http.post("/api/get_sign_pic").then(res2 => {
					this.userinfo.sign_img = res2.data.handsign
				})
			})
		},
		getData1() {
			this.$http.post("/api/stu_leave_one", { id: this.id }).then(res => {
				res.data.files = JSON.parse(res.data.files)
				try {
					res.data.remark = JSON.parse(res.data.remark)
				} catch (error) {
					res.data.remark = {}
				}
				this.EditItem = res.data
			})

		},
		getData() {
			this.$http.post("/api/stu_intership_leave_one", { id: this.id }).then(res => {
				if (res.data.leave_start && res.data.leave_end) {

					res.data.leave_start = res.data.leave_start.substring(0, 10)
					res.data.leave_end = res.data.leave_end.substring(0, 10)

				}
				// 图片
				if (res.data.files) {
					res.data.files = JSON.parse(res.data.files)
				}
				if (res.data.remark) {
					try {
						res.data.remark = JSON.parse(res.data.remark)
					} catch (error) {
						res.data.remark = { contact: "", contactTel: "" }
					}
				}
				if (res.data.leave_url) {
					res.data.fieldname = [{
						fileName: 1,
						fileUrl: res.data.leave_url
					}];
				}
				this.EditItem = res.data
			})
		},
		closeDialog() {
			this.$$parent(this, "dialogshow", false);
		},
		uploadAttch(e) {

			this.EditItem.files.push(e)
		},
		calcTotalDay(lastcheck) {
			let DataInfo = this.EditItem;

			if (DataInfo.leave_start && DataInfo.leave_start_time !== '' && DataInfo.leave_end && DataInfo
				.leave_end_time !== '') {
				let start = new Date(DataInfo.leave_start).getTime();
				let end = new Date(DataInfo.leave_end).getTime();
				if (end < start) {
					this.$message({
						type: 'error',
						message: '结束日期错误！'
					});

					return false;
				} else {
					//计算日期间隔
					if (start == end) {
						if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 0) {
							DataInfo.leave_num = 0.5;
							this.getProcessID(1);
						} else if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 1) {
							DataInfo.leave_num = 1;
							this.getProcessID(1);
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 0) {

							this.$message({
								type: 'error',
								message: '结束时间错误！'
							});
							return false;
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 1) {
							DataInfo.leave_num = 0.5;
							this.getProcessID(1);
						}
					} else {
						let day = (end - start) / (24 * 60 * 60 * 1000);
						//console.log("day:" + day);
						let weekend = 0;
						for (let i = 0; i < day; i++) {
							let n = new Date(start + (24 * 60 * 60 * 1000) * i).getDay();
							if (n == 0 || n == 6) { //周末不计算
								//weekend++;
							}
						}
						if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 0) {
							day += 0.5;
						} else if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 1) {
							day += 1;
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 0) {
							day += 0;
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 1) {
							day += 0.5;
						}
						DataInfo.leave_num = day - weekend;
					}
				}
				return true;
			} else {
				if (lastcheck) {
					this.$message({
						type: 'error',
						message: '请填写完整'
					});
				}
				return false;
			}

		},

		preCheckData(e) {

			let DataInfo = this.EditItem;


			if (!DataInfo.leave_type) {

				this.$message({
					type: 'error',
					message: '请选择请假类型'
				});
				return false;
			}
			if (!DataInfo.leave_num) {

				this.$message({
					type: 'error',
					message: '请选择请假日期'
				});
				return false;
			}

			if (!DataInfo.content) {

				this.$message({
					type: 'error',
					message: '请输入请假事由'
				});
				return false;
			}
			if (!DataInfo.remark) {
				this.$message({
					type: 'error',
					message: '请输入工作安排'
				});
				return false;
			}
			if (this.needChoose && this.choosedUser.length == 0) {
				this.$message({
					type: 'error',
					message: '请选择审批人'
				});
				return false;
			}
			return true

		},
		saveForm() {

			if (!this.preCheckData()) {
				return
			}
			let EditItem = JSON.parse(JSON.stringify(this.EditItem));
			EditItem.sp_status = 0;
			EditItem.files = JSON.stringify(EditItem.files);
			EditItem.title = "[" + this.userinfo.dept_name + "]" + this.userinfo.username + "申请" + EditItem.leave_num +
				"天" + EditItem.leave_type

			this.$http.post("/api/leave_list_edit", EditItem).then(res => {
				this.$message({
					type: 'success',
					message: '保存成功'
				});
				this.$parent.getList();
				this.$parent.dialogshow = false;
			})
		},
		isChooseuser(e) {//设置是否需要选人
			this.needChoose = e
		},
		chooseUser(e) {//设置选择的人

			this.choosedUser = e

		},
		submitForm(st) {

			if (st == 2) {//审批拒绝
				if (!this.spword) {
					this.$message.error("请填写审批拒绝原因")
					return
				}
			}



			this.$confirm('确定审核?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				if (!this.taped) {
					this.taped = 1
					setTimeout(() => {
						this.taped = 0
					}, 3000);
				} else {

					return
				}
				if (!this.userinfo.sign_img) {
					this.$alert("请先在小程序设置手写签名！")
					return
				}



				this.$http.post("/api/stu_leave_form_sp", {
					id: this.EditItem.id,
					sp_status: st,
					sp_word: this.spword,
					sp_sign: this.userinfo.sign_img
				}).then(res => {
					this.$message({
						type: 'success',
						message: '审核成功!'
					});
					this.$$parent(this, "getList");
					this.$$parent(this, "dialogshow", false);


				})



			})

		}
	},
}
</script>

<style></style>
